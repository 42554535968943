<template>
  <div class="input-checkbox-wrapper">
    <input :id="id" type="checkbox" class="checkbox" v-model="checkboxState" />
    <InputLabel :text="labelText" :id="id" class="custom-label-wrapper" />
  </div>
</template>

<script>
import { generateUID } from "../../utils/generateUID";

import InputLabel from "../elements/InputLabel";

export default {
  name: "InputCheckbox",
  props: {
    id: {
      type: String,
      default: generateUID(),
    },
    labelText: {
      type: String,
      default: "Default Checkbox Label",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      checkboxState: this.modelValue,
    };
  },
  watch: {
    checkboxState: function () {
      this.$emit("update:modelValue", this.checkboxState);
    },
  },
  components: {
    InputLabel,
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

.input-checkbox-wrapper {
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-left: 62px;
  }

  @media (max-width: 425px) {
    margin-left: 0;
    margin-top: 16px;
  }

  .checkbox {
    -webkit-appearance: none;
    width: 22px;
    height: 22px;
    border: 1px solid $main-grey;
    border-radius: 5px;
    position: relative;
    margin: 0px;

    &:checked {
      &:after {
        content: "\2714";
        font-size: 16px;
        position: absolute;
        top: -1px;
        left: 3px;
        color: $main-orange;
        width: 15px;
        height: 22px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .custom-label-wrapper {
    margin-left: 8px;
    min-height: initial;
  }
}
</style>
