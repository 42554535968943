<template>
  <PrimaryTemplate
    :step="step"
    :buttonFunction="submit"
    :mainTitle="'Expectations from working at Azati'"
    :needBackButton="true"
    :backFunction="back"
    :loading="loading"
  >
    <DropdownInput
      labelText="Position Information"
      :placeholderText="primaryDropdownPlaceholderText"
      :options="positionArray"
      :mandatory="true"
      v-model="position"
      :disabled="customPosition"
    />

    <div class="different-position-wrapper">
      <ToggleButton
        text="Other position"
        v-model="customPosition"
        :class="{ 'custom-position': customPosition }"
        @change="handleToggleButtonClick"
      />
      <InputText
        name="different-position"
        placeholder="Desired position"
        v-model="position"
        v-show="customPosition"
      />
      <div
        class="validation-message-wrapper"
        :class="{ 'custom-position': customPosition }"
      >
        <InputValidationMessage :message="errorMessages.position" />
      </div>
    </div>

    <div class="salary-expectations-wrapper">
      <div class="single-item">
        <label class="input-label">Salary desired in USD</label>
        <InputText
          name="minSalary"
          labelText=""
          placeholder="Minimum"
          v-model="minSalary"
        />
        <div class="validation-message-wrapper">
          <InputValidationMessage :message="errorMessages.minSalary" />
        </div>
      </div>
      <div class="single-item">
        <label class="input-label">Optimal salary in USD</label>
        <InputText
          name="optimalSalary"
          labelText=""
          placeholder="Max"
          v-model="optimalSalary"
        />
        <div class="validation-message-wrapper">
          <InputValidationMessage :message="errorMessages.optimalSalary" />
        </div>
      </div>
    </div>

    <div class="work-start-content">
      <div class="single-item">
        <UniversalMonthPicker
          labelText="Date you can start work"
          v-model="workStartDate"
          :disabled="asap"
        />
        <InputCheckbox
          labelText="As soon as possible"
          v-model="asap"
          @change="handleAsapCheckboxChange"
        />
      </div>
    </div>
    <div v-if="errorMessage" class="error-message red">
      {{ errorMessage }}
    </div>
  </PrimaryTemplate>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { numeric, required } from "@vuelidate/validators";
import { getErrorMessage } from "../utils/getErrorMessage.js";

import PrimaryTemplate from "@/layouts/PrimaryTemplate.vue";
import DropdownInput from "../components/utility/DropdownInput";
import ToggleButton from "../components/elements/ToggleButton";
import InputText from "../components/elements/InputText";
import InputValidationMessage from "../components/elements/InputValidationMessage";
import UniversalMonthPicker from "../components/utility/UniversalMonthPicker";
import InputCheckbox from "../components/elements/InputCheckbox";
import { update } from "../api/formdata";

// import CustomVueSelect from "../components/elements/CustomVueSelect";
// import CustomDropdownComponent from "@/components/serviceComponents/CustomDropdownComponent.vue";
// import ToggleButton from "@/components/serviceComponents/ToggleButton.vue";
// import InputComponent from "@/components/serviceComponents/InputComponent.vue";

export default {
  name: "Expectations",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      step: "Expectations",
      positionArray: JSON.parse(sessionStorage.getItem("positions")),
      customPosition: false,
      errorMessage: null,
      loading: false,
    };
  },
  computed: {
    position: {
      get() {
        return this.$store.getters.getPosition;
      },
      set(value) {
        this.$store.dispatch("setThirdStepField", {
          field: "position",
          value: value,
        });
      },
    },
    minSalary: {
      get() {
        return this.$store.getters.getMinSalary;
      },
      set(value) {
        this.$store.dispatch("setThirdStepField", {
          field: "minSalary",
          value: value,
        });
      },
    },
    optimalSalary: {
      get() {
        return this.$store.getters.getOptimalSalary;
      },
      set(value) {
        this.$store.dispatch("setThirdStepField", {
          field: "optimalSalary",
          value: value,
        });
      },
    },
    workStartDate: {
      get() {
        return this.$store.getters.getWorkStartDate;
      },
      set(value) {
        this.$store.dispatch("setThirdStepField", {
          field: "workStartDate",
          value: value,
        });
      },
    },
    asap: {
      get() {
        return this.$store.getters.getAsapMarkValue;
      },
      set(value) {
        this.$store.dispatch("setThirdStepField", {
          field: "asap",
          value: value,
        });
      },
    },
    primaryDropdownPlaceholderText: function () {
      if (this.position !== "") {
        return this.position;
      } else {
        return "Desired position";
      }
    },
    errorMessages: function () {
      return {
        position: getErrorMessage(this.v$.position.$errors),
        minSalary: getErrorMessage(this.v$.minSalary.$errors),
        optimalSalary: getErrorMessage(this.v$.optimalSalary.$errors),
      };
    },
  },
  created() {
    if (this.position !== "" && !this.positionArray.includes(this.position))
      this.customPosition = true;

    // if (this.workStartDate === "Как можно скорее") this.asap = true;
  },
  methods: {
    handleToggleButtonClick() {
      this.position = "";
    },
    handleAsapCheckboxChange() {
      this.workStartDate = this.asap === true ? "As soon as possible" : "";
    },
    async submit() {
      this.v$.$touch();
      let modifyLoading = this.modifyLoading;
      let setError = this.setError;
      let router = this.$router;
      if (!this.v$.$invalid) {
        modifyLoading(true);
        setError(null);

        let thisData = {
          position: this.position,
          minSalary: this.minSalary,
          optimalSalary: this.optimalSalary,
          workStartDate: this.workStartDate,
          asap: this.asap,
        };
        let currentData = JSON.parse(localStorage.getItem("data"));
        currentData.data.push(thisData);

        await update(currentData).then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            modifyLoading(false);

            let thisDataStringified = JSON.stringify(currentData);
            localStorage.setItem("data", thisDataStringified);
            router.push({ path: "/education" });
          } else {
            modifyLoading(false);
            if (response.status == 400) {
              setError(
                "Validation error! Please check the accuracy of the entered data!"
              );
            } else {
              setError("Form submission error! Please try again later!");
            }
            console.log("Error: ");
            console.log(response.status);
          }
        });
      }
    },
    back() {
      let currentData = JSON.parse(localStorage.getItem("data"));
      currentData.data.pop();
      let thisDataStringified = JSON.stringify(currentData);
      localStorage.setItem("data", thisDataStringified);

      this.$router.push({ path: "/basic-information" });
    },
    modifyLoading(loading) {
      this.loading = loading;
    },
    setError(message) {
      this.errorMessage = message;
    },
  },

  components: {
    PrimaryTemplate,
    DropdownInput,
    ToggleButton,
    InputText,
    InputValidationMessage,
    UniversalMonthPicker,
    InputCheckbox,
  },

  validations() {
    return {
      position: {
        required,
      },
      minSalary: {
        numeric,
        // required,
      },
      optimalSalary: {
        numeric,
        // required,
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
.different-position-wrapper {
  width: 100%;
  margin-top: -15px;

  .toggle-wrapper {
    margin-bottom: 5px;

    &.custom-position {
      margin-bottom: 10px;
    }
  }

  .validation-message-wrapper {
    min-height: 20px;
    &.custom-position {
      min-height: 25px;
    }
  }
}

.salary-expectations-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;

  &::before {
    content: "";
    position: absolute;
    left: 49%;
    height: 2px;
    width: 2%;
    background-color: $main-grey;
    top: calc(50% - 5px);
  }

  .single-item {
    max-width: 47%;
    width: 100%;

    .dropdown-input-wrapper {
      padding-bottom: 0px;
    }

    .validation-message-wrapper {
      min-height: 25px;
    }
  }

  @media screen and (max-width: 519.88px) {
    &::before {
      display: none;
    }

    .single-item {
      max-width: 100%;
      width: 100%;
    }
  }
}

.work-start-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;

  .single-item {
    max-width: 47%;
    width: 100%;
  }

  .input-checkbox-wrapper {
    margin-top: 10px;
  }

  @media screen and (max-width: 519.88px) {
    .single-item {
      max-width: 100%;
      width: 100%;
    }
  }
}
.red {
  color: red;
}
:disabled {
  opacity: 0.4;
}
.input-label {
  color: #8998ac;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  min-height: 20px;
  display: flex;
}
</style>
